.label-text {
  font-size: 14px;
  // text-transform: uppercase;
  font-weight: 400;
  color: #fff;
}

.label-text-white {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $white;
}

.text-center {
  text-align: center;
}
