.text-field {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  border-radius: 4px;
  color: #212121;
  font-weight: 400;
  height: 42px;
  margin-bottom: 0;
}

.text-field::placeholder {
  color: var(--inputPlaceholderText);
}
